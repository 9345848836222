<template>
  <div class="w-100 my-5">
    <div v-if="userList">
      <div class="row mx-auto box-white" v-if="(userType >= -1 && userType <= 2)">
        <div class="col-sm my-1 vertical-center">
          <span class="font-bold font-15 alpha-07">{{ $t(userType === 1 ? 'organizer' : userType === -1 ? 'unapproved_organizer' : userType === 2 ? 'guest' : 'single_user') }}:</span>
          <span class="mx-5 font-bold font-20 color-blue">{{ items.length }}</span>
        </div>
        <div :class="`col-sm my-1 vertical-center ${isMobile ? '' : 'justify-content-end'}`">
          <input class="w-75" v-model="keyword" />
          <v-btn class="ml-2" icon small @click="keyword = ''" :disabled="!keyword">
            <i :class="`flaticon-circle ${keyword.length > 0 ? 'color-blue' : ''}`"></i>
          </v-btn>
        </div>
      </div>
      <div class="row my-5" v-if="(items.length > 0)">
        <div :class="`col-sm${colSmSize}`" v-for="(item, index) in items.slice(0, Math.min(pageIndex * PAGE_LIMIT_20, items.length))" :key="(item, index)">
          <div class="my-2 box-white d-flex flex-column">
            <div class="vertical-center pointer" @click="$router.push(`/users/${userType}/${item.userId}`)">
              <img class="circle-image-80" :src="item.thumbnailUrl || item.photoUrl || assets.profile" />
              <div class="ml-5 d-flex flex-column">
                <div class="mt-2 vertical-center" v-if="userType != 2">
                  <i class="fa fa-user font-12 color-blue"></i>
                  <span class="ml-2 font-bold font-15">{{ item.userName }}</span>
                </div>
                <div class="mt-2 vertical-center" v-if="item.email">
                  <i class="socicon-mail font-12 color-blue"></i>
                  <span class="ml-2 font-12">{{ getUserEmail(item.email) }}</span>
                </div>
                <div class="mt-2 vertical-center" v-if="item.phone">
                  <i class="fa fa-phone font-12 color-blue"></i>
                  <span class="ml-2 font-12">{{ item.phone }}</span>
                </div>
                <div class="mt-2 vertical-center" v-if="item.deviceType">
                  <i class="fa fa-mobile-alt font-20 color-blue"></i>
                  <span :class="`ml-2 w-100 font-12 ellipsis ${item.deviceTypeColor}`" :title="item.deviceType">{{ item.deviceType.substring(0, 40) }}</span>
                </div>
              </div>
            </div>
            <div class="mt-5 vertical-center">
              <div class="w-80px mr-5" v-if="!item.deletedAt && !item.blockedAt">&nbsp;</div>
              <div class="vertical-center mr-auto">
                <i class="fa fa-calendar-alt font-12 color-blue"></i>
                <span class="ml-2 font-12">{{ getDateStringFromTimestamp(item.createdAt) }}</span>
              </div>
              <div class="vertical-center" v-if="item.blockedAt">
                <i class="fa fa-calendar-alt font-12 color-red"></i>
                <span class="ml-2 font-12 color-red">{{ getDateStringFromTimestamp(item.blockedAt) }}</span>
                &nbsp;
                <i class="font-12 color-red">[Blocked]</i>
              </div>
              <div class="vertical-center" v-if="item.deletedAt">
                <i class="fa fa-calendar-alt font-12 color-red"></i>
                <span class="ml-2 font-12 color-red">{{ getDateStringFromTimestamp(item.deletedAt) }}</span>
                &nbsp;
                <i class="font-12 color-red">[{{ $t('deleted') }}]</i>
              </div>
            </div>
            <div class="mt-5 d-flex justify-content-end vertical-center" v-if="(item.userType === -1)">
              <v-btn class="button-normal" small @click="setUserTypeTask(item.userId, 1)">
                <span>{{ $t('approve') }}</span>
              </v-btn>
              <v-btn class="ml-5 button-red" small @click="setUserTypeTask(item.userId, 0)">
                <span>{{ $t('decline') }}</span>
              </v-btn>
            </div>
            <div class="d-flex flex-column" v-else-if="(item.userType === 1)">
              <div class="mt-5 d-flex justify-content-between vertical-center">
                <v-btn class="button-normal" small @click="$router.push(`/event/events/${item.userId}`)">
                  <span>{{ $t('event') }}</span>
                </v-btn>
                <v-btn class="button-normal" small @click="$router.push(`/transaction/transactions/u/${item.userId}`)">
                  <span>{{ $t('transaction') }}</span>
                </v-btn>
                <v-btn class="button-red" small @click="$router.push(`/transaction/withdraws/u/${item.userId}`)">
                  <span>{{ $t('withdraw') }}</span>
                </v-btn>
              </div>
              <div class="mt-2 d-flex justify-content-between vertical-center">
                <v-btn class="button-normal" small @click="$router.push(`/drink/drinks/u/${item.userId}`)">
                  <span>{{ $t('drink') }}</span>
                </v-btn>
                <v-btn class="button-normal" small @click="$router.push(`/drink/orders/u/${item.userId}`)">
                  <span>{{ $t('orders') }}</span>
                </v-btn>
                <v-btn class="button-red" small @click="$router.push(`/drink/payout/u/${item.userId}`)">
                  <span>{{ $t('withdraw') }}</span>
                </v-btn>
              </div>
            </div>
            <div class="mt-5 d-flex justify-content-end vertical-center" v-else>
              <v-btn class="ml-5 button-red" small @click="remindGuestUserTask(item.email)" v-if="(item.userType === 2)">
                <span>{{ $t('remind') }}</span>
              </v-btn>
              <v-btn class="ml-5 button-normal" small @click="$router.push(`/transaction/transactions/u/${item.userId}`)">
                <span>{{ $t('tickets') }}</span>
              </v-btn>
            </div>
          </div>
        </div>
      </div>
      <div class="my-5 d-flex justify-content-center" v-if="items.length / PAGE_LIMIT_20 > pageIndex">
        <v-btn text small @click="pageIndex++">
          <span>Load More</span>
        </v-btn>
      </div>
      <div class="py-40 center" v-if="(items.length === 0)">
        <span class="font-20 alpha-07">{{ $t('no_data_found') }}</span>
      </div>
    </div>
    <div class="py-40 center" v-else>
      <p>{{ $t('please_wait') }}</p>
      <b-spinner type="grow"></b-spinner>
    </div>
  </div>
</template>


<script>
import { httpsCallable } from 'firebase/functions';
import { functions } from '../../../main';
import { getDecimalFormat, getDateStringFromTimestamp, getUserEmail, showLoading } from '../../../functions';

import profile from '@/assets/image/profile.jpg';

export default {
  name: 'UserList',
  computed: {
    isMobile() {
      return this.$store.state.isMobile;
    },
    colSmSize() {
      return this.$store.state.colSmSize;
    },
    userType() {
      return parseInt(this.$route.params.type);
    },
    userList() {
      return this.$store.state.userList;
    }
  },
  watch: {
    userType() {
      if (this.keyword) {
        this.keyword = '';
      } else {
        this.refreshData();
      }
    },
    userList(newList, oldList) {
      const oldCount = oldList ? oldList.length : 0;
      const newCount = newList ? newList.length : 0;
      if (oldCount !== newCount) {
        this.refreshData();
      }
    },
    keyword() {
      this.refreshData();
    }
  },
  data() {
    return {
      assets: {
        profile
      },
      pageIndex: 1,
      items: [],
      keyword: ''
    }
  },
  mounted() {
    this.refreshData();
  },
  methods: {
    getDecimalFormat,
    getDateStringFromTimestamp,
    getUserEmail,
    refreshData() {
      if (this.userList) {
        const keyword = this.keyword.toLowerCase();
        const items = this.userList.filter(element => this.filterUser(element, keyword));
        items.sort((a, b) => (a.createdAt.seconds < b.createdAt.seconds) ? 1 : -1);
        this.pageIndex = 1;
        this.items = items;
      }
    },
    filterUser(userInfo, keyword) {
      if (userInfo.userType !== this.userType) {
        return false;
      }
      if (keyword !== '') {
        var userNameEmailPhone = userInfo.userName.toLowerCase();
        if (userInfo.email) {
          userNameEmailPhone += `\n${userInfo.email.toLowerCase()}`;
        }
        if (userInfo.phone) {
          userNameEmailPhone += `\n${userInfo.phone}`;
        }
        if (!userNameEmailPhone.includes(keyword)) {
          return false;
        }
      }
      return true;
    },
    setUserTypeTask(userId, userType) {
      if (!confirm(this.$t(`${userType === 1 ? 'confirm_approve_organizer' : 'confirm_approve_single_user'}`))) {
        return;
      }
      const params = {
        functionName: 'setUserType',
        userId: userId,
        userType: userType
      };
      const loader = showLoading(this, 1);
      const func = httpsCallable(functions, this.ADMIN_FUNCTION);
      func(JSON.stringify(params)).then(response => {
        loader.hide();
        if (response.data === this.RESULT_SUCCESS) {
          this.$toast.success(this.$t('success'));
        } else {
          this.$toast.error(response.data);
        }
      }).catch(error => {
        loader.hide();
        this.$toast.error(error.code + ', ' + error.message);
      });
    },
    remindGuestUserTask(email) {
      if (!email) {
        this.$toast.error('Email is required.');
        return;
      }
      const params = {
        functionName: 'remindGuestUser',
        email: email
      };
      const loader = showLoading(this, 1);
      const func = httpsCallable(functions, this.ADMIN_FUNCTION);
      func(JSON.stringify(params)).then(response => {
        loader.hide();
        if (response.data === this.RESULT_SUCCESS) {
          this.$toast.success(this.$t('success'));
        } else {
          this.$toast.error(response.data);
        }
      }).catch(error => {
        loader.hide();
        this.$toast.error(error.code + ', ' + error.message);
      });
    }
  }
};
</script>